<template>
    <div class="px-8 mt-4">
      <v-dialog
          v-model="prViewItemDialog"
          persistent
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          class="prModal"
          scrollable
        >
          <v-card>
            <v-toolbar
              dark
              color="green darken-2"
              max-height="64"
            >
              <v-toolbar-title>{{ canvasData.isDBM == 0 ? canvasData.PRCODE : canvasData.PRCODETemp }}</v-toolbar-title>
                <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="clickCloseMainDialog()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <div>
          </div>

        <!-----Header----->
        <v-card-text style="max-height: 900px">
          <div>

            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                 <v-subheader class="font-weight-bold text-h6">Purchase Request</v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-n2">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Entity Name</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>Davao del Norte State College</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">Fund Cluster</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{prDetails.FundCode}}</v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-n8">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Office/ Section</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{prDetails.OfficeDescription}}</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">PR No.</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{ canvasData.isDBM == 0 ? canvasData.PRCODE : canvasData.PRCODETemp }}</v-subheader>
              </v-col>
            </v-row>

            <v-row class="mt-n8 mb-4">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Date</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>{{canvasData.DateCreated}}</v-subheader>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">Responsibility Center Code</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-subheader>---</v-subheader>
              </v-col>
            </v-row>
          </div>

            <v-row>
              <v-col lg="12" class="py-4 px-7">
                <v-card class="" >
                <v-data-table
                  v-model="formData.Lists"
                  :headers="headers"
                  :items="itemList"
                  item-key="ppmpItemID"
                  class="elevation-1 mt-4"
                  show-select
                >
    <template v-slot:top>
      <v-toolbar flat>
          <span class="green--text text--darken-2">List Of Items</span> 
        <v-spacer></v-spacer>
            <v-btn
             outlined
             :disabled="formData.Lists.length < 1"
              color="green darken-2"
              @click="bidTypeDialog = true"
              class="mr-2"
            >
              <v-icon>mdi-plus</v-icon>
              Bid Item/s
            </v-btn>
            <v-text-field
              :value="'₱ '+ formatPrice(totalAmount)"
              color="green darken-2"
              style="max-width: 250px"
              readonly
              outlined
              dense
              prefix="Total: "
              filled
              hide-details
            ></v-text-field>
            <!-- <v-btn
             outlined
             :disabled="formData.Lists.length < 1"
              color="red darken-2"
              @click="Generate()"
              class="mr-2"
            >
              <v-icon>print</v-icon>
              Generate AOQ
            </v-btn> -->
      </v-toolbar>
    </template>
    <template v-slot:[`item.UnitCost`]="{ item }">
      <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost))}}</span>
    </template>
    <template v-slot:[`item.Amount`]="{ item }">
      <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
    </template>

                </v-data-table>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                 <v-subheader class="font-weight-bold text-h6">Abstract of Quotation</v-subheader>
              </v-col>
            </v-row>
          <v-alert
            text
            type="success"
            color="header"
            v-if="formData.AOQLists.length == 0"
          >
            No items bade! Items for bidding will be displayed here...
          </v-alert>
          <div v-if="formData.AOQLists.length > 0">
            <v-form @submit.prevent ref="AOQFormRef">
            <v-row class="mt-n1 mr-3">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Type of Bidding</v-subheader>
              </v-col>
              <v-col cols="3">
                 <span v-if="AOQData.AOQTypeID == 1">Item Bid</span>
                 <span v-if="AOQData.AOQTypeID == 2">Lot Bid</span>
                 <span v-if="AOQData.AOQTypeID == null">Not yet selected</span>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">Date Received from PSU(RFQ)</v-subheader>
              </v-col>
              <v-col cols="3">
              <v-menu
                dense
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="AOQFormdata.DateReceived"
                transition="scale-transition"
                offset-y
                class="mb-4"
                min-width="auto"
                
              >
                <template  v-slot:activator="{ on, attrs }">
                  <v-combobox
                    dense
                    v-model="AOQFormdata.DateReceived"
                    label="Date Received"
                    :rules="[formRules.required]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <v-date-picker
                  v-model="AOQFormdata.DateReceived"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu1 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu1.save(AOQFormdata.DateReceived)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              </v-col>
            </v-row>

            <v-row class="mt-n5 mr-3">
              <v-col cols="3">
                <v-subheader class="font-weight-bold">Mode Of Procurement</v-subheader>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="AOQFormdata.mopID"
                  :items="mop"
                  item-text="mopDescription"
                  item-value="mopID"
                  :rules="[formRules.required]"
                  label="Mode of Procurement"
                  clearable
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="3">
                <v-subheader class="font-weight-bold">Closing Date</v-subheader>
              </v-col>
              <v-col cols="3">
              <v-menu
                dense
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="AOQFormdata.ClosingDate"
                transition="scale-transition"
                offset-y
                class="mb-4"
                min-width="auto"
              >
                <template  v-slot:activator="{ on, attrs }">
                  <v-combobox
                    dense
                    v-model="AOQFormdata.ClosingDate"
                    label="Closing Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <v-date-picker
                  v-model="AOQFormdata.ClosingDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu2.save(AOQFormdata.ClosingDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              </v-col>
            </v-row>
            </v-form>
            <v-row class="mt-n8 mb-4">
              <v-col lg="12" class="py-4 px-7">
                <v-card class="" >
                <v-data-table
                  v-model="AOQLists"
                  :headers="headersAOQ"
                  :items="formData.AOQLists"
                  item-key="ppmpItemID"
                  class="elevation-1 mt-4"
                  show-select
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                        <span class="green--text text--darken-2">Abstract of Quotation</span> 
                      <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="#E45200"
                            class="mr-2"
                            :disabled="AOQLists.length == 0"
                            @click="deleteItem()"
                          >
                            <v-icon>delete</v-icon>
                            DELETE
                          </v-btn>
                          <v-btn
                            outlined
                            :disabled="formData.AOQLists.length == 0"
                            color="green darken-2"
                            @click="addSupplierDialog = true"
                            class="mr-2"
                          >
                            <v-icon>mdi-plus</v-icon>
                            Add Suppliers
                          </v-btn>
                          <v-btn
                            color="#E45200"
                            class="mr-2 white--text"
                            :disabled="AOQSuppliers.length == 0"
                            @click="saveAOQ()"
                            :loading="isLoading"
                          >
                            <v-icon>mdi-check</v-icon>
                            SAVE AOQ
                          </v-btn>
                    </v-toolbar>
                  </template>
                <template v-slot:body="{ items }">
                  <tbody v-if="items.length > 0">
                    <tr v-for="(item, i) in items" :key="i">
                      <td>
                        <v-checkbox
                          v-model="AOQLists"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          hide-details
                        />
                      </td>
                      <td align="center">{{ i + 1 }}</td>
                      <td align="center">{{ item.Qty }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost) }}</td>
                      <td align="end">{{ formatPrice(item.UnitCost * item.Qty) }}</td>
                      <td>{{ item.ItemName }}</td>
                      <td>{{ item.itemDescription }}</td>
                      <td align="center" v-for="(supplier, j) in item.suppliers" :key="j" @click="editAOQ(supplier, item)">
                        <v-badge
                          :color="colors[supplier.Qty * supplier.UnitCost > 0 && supplier.rank <= 2 ? supplier.rank : supplier.Qty * supplier.UnitCost > 0 ? 3 : 4]"
                          :content="supplier.Qty * supplier.UnitCost > 0 ? supplier.rank + 1 : null"
                        >
                        <span :style="supplier.Qty * supplier.UnitCost > item.UnitCost * item.Qty ? 'color:red;' : ''">
                        {{supplier.Qty + ' x ' + formatPrice(supplier.UnitCost) + ' = ' + formatPrice(supplier.Qty * supplier.UnitCost)}}
                        </span>
                        </v-badge>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr><td :colspan="headersAOQ.length + 1" align="center">No items available</td></tr>
                  </tbody>
                  <tbody v-if="AOQData.AOQTypeID == 2 && AOQSuppliers.length > 0">
                    <tr style="background: lightgrey">
                      <td colspan="7" align="center" class="text-no-wrap">Overall Total: <b>₱ {{formatPrice(getOverAllTotal())}}</b></td>
                      <td align="center" v-for="(supplier, j) in AOQData.Suppliers" :key="j">
                        <b class="text-no-wrap">₱ {{formatPrice(getTotalPerSupplier(supplier))}}</b>
                      </td>
                    </tr>
                  </tbody>
                </template>
                </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </div>
            <!-- <v-row>
              <v-col lg="12" class="py-4 px-7">
                <v-card class="" >
                <v-toolbar flat>
                        <span class="green--text text--darken-2">Abstract of Quotation</span> 
                      <v-spacer></v-spacer>
                          <v-btn
                            outlined
                            color="#E45200"
                            class="mr-2"
                          >
                            <v-icon>delete</v-icon>
                            DELETE
                          </v-btn>
                          <v-btn
                            color="#E45200"
                            class="mr-2 white--text"
                          >
                            <v-icon>mdi-plus</v-icon>
                            GENERATE AOQ
                          </v-btn>
                    </v-toolbar>
                <div class="text-center d-flex pb-4">
                  <v-btn @click="all">
                    all
                  </v-btn>
                  <div>{{ panel }}</div>
                  <v-btn @click="none">
                    none
                  </v-btn>
                </div>

                <v-expansion-panels
                  v-model="panel"
                  multiple
                  focusable
                  class="pa-5"
                >
                  <v-expansion-panel
                    v-for="(data,i) in formData.AOQLists"
                    :key="i"
                  >
                  <div v-for="(item,j) in data"
                    :key="j"
                  >
                    <v-expansion-panel-header>Item: {{ item.ItemName }}</v-expansion-panel-header>
                  </div>
                  <v-expansion-panel-content>
                      table sample
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div v-if="formData.AOQLists.length == 0">
                  No items available
                </div>
                </v-card>
              </v-col>
            </v-row> -->
        </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog v-model="bidTypeDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="subheader font-weight-bold"> Select type of Bid</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="bidTypeDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @submit.prevent ref="formRef1">
          <v-card-text>
                  <v-chip-group
                    v-model="AOQData.AOQTypeID"
                    active-class="header white--text"
                  >
                    <v-chip value="1" x-large class="px-10">
                      <v-icon left>
                        mdi-format-list-bulleted
                      </v-icon>
                      Item Bid
                    </v-chip>

                    <v-chip value="2" x-large class="px-10" :disabled="formData.Lists.length <= 1">
                      <v-icon left>
                        mdi-list-box-outline
                      </v-icon>
                      Lot Bid
                    </v-chip>

                  </v-chip-group>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="bidTypeDialog = false"> Cancel </v-btn>
          <v-btn
            color="header"
            class="white--text"
            @click="saveBidding"
            :loading="isLoading"
            :disabled="!AOQData.AOQTypeID"
          >
          <v-icon>mdi-check</v-icon>
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      
    <v-dialog v-model="addSupplierDialog" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold"> Select Suppliers</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="addSupplierDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form @submit.prevent ref="formRef2">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="AOQData.Suppliers"
                    :items="suppliers"
                    color="header"
                    item-text="CompanyName"
                    item-value="SupplierID"
                    chips
                    label="Suppliers"
                    multiple
                    deletable-chips
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="addSupplierDialog = false"> Cancel </v-btn>
          <v-btn
            color="green darken-1"
            class="white--text"
            @click="saveSuppliers"
            :loading="isLoading"
          >
            <v-icon>mdi-check-circle</v-icon>
            save
          </v-btn>
          <!-- <v-btn
            v-else
            color="green darken-1"
            text
            @click="update"
            :loading="isLoading"
          >
            Update
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editSupplierDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subtitle-1 font-weight-bold"> Edit Quotation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="editSupplierDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            <v-container>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters v-if="currentItem">
                    <v-col cols="12" sm="3">
                      <strong>Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{formatPrice(currentItem.Qty * currentItem.UnitCost)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="currentSupplier">
                    <v-col cols="12" sm="3">
                      <strong>Supplier: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{getSupplierName(currentSupplier.SupplierID)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="currentItem">
                    <v-col cols="12" sm="3">
                      <strong>Item: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{currentItem.ItemName}}
                    </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-form @submit.prevent ref="formDataEdit">
                    <v-row no-gutters>
                       <v-col cols="12">
                          <v-textarea
                            v-model="AOQFormdata.supplierItemDescription"
                            class="ml-sm-1"
                            color="header"
                            rows="1"
                            auto-grow
                            :readonly="!editItemDesc"
                            :append-icon="editItemDesc ? 'mdi-close-circle' : 'mdi-pencil-box'"
                            @click:append="editItemDesc = !editItemDesc"
                            label="Item Description">
                          </v-textarea>
                      </v-col>
                      <v-col cols="12">
                      <v-switch
                        v-model="switchEditQuotation"
                        inset
                        color="header"
                        class="mb-4"
                        hide-details
                        label="Edit Qty & UnitCost"
                      ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field 
                          v-model="AOQFormdata.supplierQty" 
                          class="mr-sm-1"
                          color="header"
                          @keyup="computeQuotation()"
                          :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                          :disabled="!switchEditQuotation"
                          label="Quantity">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="AOQFormdata.supplierUnitCost"
                          class="ml-sm-1"
                          color="header"
                          @keyup="computeQuotation()"
                          :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                          :disabled="!switchEditQuotation"
                          label="Unit Cost">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="AOQFormdata.supplierAmount"
                          @keyup="computeAmount()"
                          color="header"
                          :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                          label="Total Amount">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="editSupplierDialog = false">
          Cancel
          </v-btn>
          <v-btn color="grey darken-1" outlined @click="reset()">
          Reset
          </v-btn>
          <v-btn
            color="green darken-1"
            @click="updateAOQ"
            :loading="isLoading"
            class="white--text"
          >
          <v-icon>mdi-update</v-icon>
            Update
          </v-btn>
          <!-- <v-btn
            v-else
            color="green darken-1"
            text
            @click="update"
            :loading="isLoading"
          >
            Update
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <div class="mx-6">
          Are you sure you want to delete the selected item/s?
        </div>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined @click="dialogDelete = false">Cancel</v-btn>
          <v-btn color="red darken-2 white--text" @click="deleteItemConfirm()"><v-icon>mdi-delete</v-icon> Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSaveAOQ" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <div class="mx-6">
          Are you sure you want to save the AOQ?
        </div>
        <v-card-actions class="mt-5">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-2" outlined @click="dialogSaveAOQ = false">Cancel</v-btn>
          <v-btn color="#E45200" class="white--text" @click="saveAOQConfirm()"><v-icon>mdi-check</v-icon> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
    </div>
</template>
    
    <script>
      export default {
	props : {
        canvasData: {},
	},
        data: () => ({
          prDetails: [],
          headers: [
              {
                text: 'Item Name',
                align: 'start',
                sortable: false,
                value: 'ItemName',
              },
              { text: 'Description', value: 'itemDescription' , style: 'max-width: 200px;', sortable: false },
              { text: 'Unit Measure', value: 'UnitMeasureDescription', align: "center", sortable: false},
              { text: 'Quantity', value: 'Qty', align: "center", sortable: false },
              { text: 'Unit Cost', value: 'UnitCost', align: "end", sortable: false},
              { text: 'Amount', value: 'Amount', align: "end", sortable: false}
            ],
          headersAOQ: [
              {
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              // { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Item Name', value: 'ItemName', sortable: false },
              { text: 'Item Description', value: 'ItemDescription', sortable: false }
            ],
          loading: false,
          prViewItemDialog: false,
           footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
           options: {},
          isLoading: false,
          formData: {
              Lists: [],
              PRID: null,
              AOQLists: [],
            },
          itemList: [],
          totalAmount: 0,
          bidTypeDialog: false,
          addSupplierDialog: false,
          suppliers: [],
          AOQData: {
            AOQTypeID: '1',
            Suppliers: [],
            UnitCost: null,
            Qty: null,
            ItemDescription: null
          },
          panel: [],
          items: 5,
          AOQLists: [],
          AOQSuppliers: [],
          editRes: true,
          editSupplierDialog: false,
          currentSupplier: null,
          currentItem: null,
          AOQFormdata: {
            supplierQty: null,
            supplierUnitCost: null,
            supplierAmount: null,
            supplierItemDescription: null,
            mopID: null,
            DateReceived: null,
            ClosingDate: null,
          },
          colors: ['red', 'green', 'blue', 'grey', 'transparent'],
          mop: [],
          menu1: false,
          menu2: false,
          fadeAwayMessage: {
            show: false,
            type: "success",
            header: "Successfully Added!",
            message: "",
            top: 10,
          },
          dialogDelete: false,
          dialogSaveAOQ: false,
          switchEditQuotation: false,
          PRAmount: null,
          editItemDesc: false,
        }),
       watch: {
          canvasData: {
            handler (data) {
            if(data.PRID){
            this.prViewItemDialog = true;
            this.getItems();
            }
            },
            deep: true,
          },
          formData: {
            handler(data) {
              this.totalAmount = 0;
            data.Lists.forEach(item => {
              this.totalAmount += parseFloat(item.Qty) * parseFloat(item.UnitCost)
            });
            },
            deep: true,
          },
          AOQFormdata: {
            handler() {
            },
            deep: true,
          },
        },
        created(){
          this.getAllSuppliers();
          this.getMOP();
        },
        methods: {
        all () {
        this.panel = [...Array(this.items).keys()].map((k, i) => i)
        },
        // Reset the panel
        none () {
          this.panel = []
        },
        checkBalance(){
          let actualAmount = parseFloat(this.currentItem.Qty * this.currentItem.UnitCost)
          let amount = parseFloat(this.AOQFormdata.supplierAmount)
          // console.log(actualAmount >= amount || "Insuficient Balance", actualAmount, amount)
          return actualAmount >= amount || "Insuficient Balance";
        },
        editAOQ(supplier, item){
          this.currentSupplier = supplier;
          this.currentItem = item;
          let qty = parseFloat(parseFloat(supplier.Qty) || item.Qty)
          let unitCost = parseFloat(parseFloat(supplier.UnitCost) || item.UnitCost)
          this.AOQFormdata.supplierQty = qty.toFixed(2);
          this.AOQFormdata.supplierUnitCost = unitCost.toFixed(2);
          this.AOQFormdata.supplierAmount = (qty * unitCost).toFixed(2)
          this.AOQFormdata.supplierItemDescription = supplier.ItemDescription || ""
          this.PRAmount = (qty * unitCost).toFixed(2)
          this.editSupplierDialog = true;
        },
        updateAOQ(){
          if(this.$refs.formDataEdit.validate()){
            const index = this.formData.AOQLists.indexOf(this.currentItem)
            const data = this.formData.AOQLists[index].suppliers
            data.forEach(item => {
                if(item.SupplierID == this.currentSupplier.SupplierID){
                  item.UnitCost =  parseFloat(this.AOQFormdata.supplierUnitCost);
                  item.Qty =  parseFloat(this.AOQFormdata.supplierQty);
                  item.ItemDescription =  this.AOQFormdata.supplierItemDescription;
                }
            });
            data.slice().sort (function(a,b) {
              return (a.Qty * a.UnitCost === 0) - (b.Qty * b.UnitCost === 0) || a.Qty * a.UnitCost - b.Qty * b.UnitCost;
            })
            .forEach (function(d,i) {
                d.rank = i;
            });
            let temp = this.formData.AOQLists;
            this.formData.AOQLists = [];
            this.formData.AOQLists = temp
            this.editSupplierDialog = false;
          }
        },
        changeIput(){
          return this.$refs.formDataEdit.validate()
        },
        Generate(AOQID) {
          window.open(this.apiUrl + "/pr/aoq/" + this.canvasData.PRID + "/" + AOQID);
        },
        clickCloseMainDialog () {
            this.formData = {
              Lists: [],
              PRID: null,
              AOQLists: []
            };
            this.AOQSuppliers = [];
            this.AOQData = {
            AOQTypeID: '1',
            Suppliers: [],
            UnitCost: null,
            Qty: null,
            ItemDescription: null
            }
            this.headersAOQ = [
              {
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              // { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Item Name', value: 'ItemName', sortable: false },
              { text: 'Item Description', value: 'itemDescription', sortable: false },
            ];
            this.itemList =[];
            this.prViewItemDialog = false;
            this.eventHub.$emit('closeCanvasModal', false);
        },
          getItems () {
            let data = new FormData();
               data.append('PRID', this.canvasData.PRID);
                  this.axiosCall(
                  '/pr/canvas/items',
                  'POST',
                  data
              )
              .then((res) => {
                this.loading = false;
                this.prDetails = res.data.data.details
                this.itemList = res.data.data.result;
                this.formData.Lists = res.data.data.result;
              })
          },
          getAllSuppliers() {
            let data = new FormData();
            data.append("searchText", '');
                  this.axiosCall(
                  '/get/all/supplier',
                  'POST',
                  data
              )
              .then((res) => {
                this.suppliers = res.data.data
              })
          },
          saveBidding(){
            if(this.$refs.formRef1.validate() && this.AOQData.AOQTypeID > 0){
              this.formData.AOQLists = this.formData.Lists;
              this.formData.AOQLists.sort(function(a, b) {
                const nameA = (a.ItemName ? a.ItemName.toUpperCase() : '') + (a.ItemDescription ? a.ItemDescription.toUpperCase() : '');
                const nameB = (b.ItemName ? b.ItemName.toUpperCase() : '') + (b.ItemDescription ? b.ItemDescription.toUpperCase() : '');
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });
              // if(this.AOQData.AOQTypeID == 2){
              //   let qty = 1;
              //   let unitCost = 0;
              //   let ppmpItemIDs = [];
              //   let items = [];
              //   this.formData.Lists.forEach(item => {
              //     unitCost = unitCost + (parseFloat(item.Qty) * parseFloat(item.UnitCost));
              //     ppmpItemIDs.push(item.ppmpItemID);
              //     items.push(item.ItemName)
              //   });
              //   this.formData.AOQLists = [{
              //     Qty: qty,
              //     UnitCost: unitCost,
              //     ppmpItemID: ppmpItemIDs,
              //     ItemName: items.join(", ")
              //   }];
              // }
              this.bidTypeDialog = false
            }
          },
          saveSuppliers(){
            if(this.$refs.formRef2.validate()){
              this.AOQSuppliers = this.AOQData.Suppliers;
              console.log(this.AOQSuppliers)
              this.headersAOQ = [{
                text: 'Item No',
                align: 'center',
                sortable: false,
                value: 'ItemNo',
              },
              {
                text: 'Quantity',
                align: 'center',
                sortable: false,
                value: 'Qty',
              },
              {
                text: 'Unit Cost',
                align: 'end',
                sortable: false,
                value: 'UnitCost',
              },
              {
                text: 'Amount',
                align: 'end',
                sortable: false,
                value: 'Amount',
              },
              // { text: 'Unit Measure', align: 'center', value: 'UnitMeasureDescription' , sortable: false},
              { text: 'Item Name', value: 'ItemName', sortable: false },
              { text: 'Item Description', value: 'itemDescription', sortable: false },
            ];
              this.AOQSuppliers.forEach(item => {
                this.headersAOQ.push({ text: this.getSupplierName(item), value: 'SupplierName', align: 'center', sortable: false })
              });
              this.formData.AOQLists.forEach(item => {
                let temp = []
                this.AOQSuppliers.forEach((supplier, index) => {
                  console.log(item)
                  if(item.suppliers && item.suppliers[index] && item.suppliers[index].SupplierID == supplier){
                    temp.push({ SupplierID: supplier, ItemDescription: item.suppliers[index].ItemDescription || null, Qty: item.suppliers[index].Qty || 0, UnitCost: item.suppliers[index].UnitCost || 0, rank: item.suppliers[index].rank || null  })
                  }
                  else{
                  temp.push({ SupplierID: supplier, ItemDescription: null, Qty: 0, UnitCost: 0  })
                    
                  }
                });
                item.suppliers = temp
              });
              this.addSupplierDialog = false
            }
          },
          reset(){
            this.AOQFormdata.supplierQty = 0;
            this.AOQFormdata.supplierUnitCost = 0;
            this.AOQFormdata.supplierAmount = 0;
          },
          getMOP(){
            this.axiosCall("/get/all/POUtil", "POST", []).then((res) => {
              // this.deliveryterm = res.data.data.deliveryterm;
              // this.supplier = res.data.data.supplier;
              // this.dod = res.data.data.dod;
              this.mop = res.data.data.mop;
              // this.paymentterm = res.data.data.paymentterm;
              // this.pod = res.data.data.pod;
            });
          },
          saveAOQ(){
            if(this.$refs.AOQFormRef.validate()){
            this.dialogSaveAOQ = true;
            }
          },
          saveAOQConfirm(){
          if(this.$refs.AOQFormRef.validate()){
            this.isLoading = true;
            let data = new FormData();
            data.append("PRID", this.canvasData.PRID);
            data.append("AOQTypeID", this.AOQData.AOQTypeID);
            data.append("mopID", this.AOQFormdata.mopID);
            data.append("DateReceived", this.AOQFormdata.DateReceived);
            data.append("ClosingDate", this.AOQFormdata.ClosingDate);
            data.append("List", JSON.stringify(this.formData.AOQLists));
            this.axiosCall("/aoq/add", "POST", data).then((res) => {
              this.isLoading = false;
              if (res.status) {
                this.$refs.AOQFormRef.reset();
                this.dialogSaveAOQ = false;
                this.Generate(res.data.data);
                this.clickCloseMainDialog();
              } else {
                this.fadeAwayMessage.show = true;
                this.fadeAwayMessage.type = "error";
                this.fadeAwayMessage.message = res.data.message;
                this.fadeAwayMessage.header = "System Message";
              }
            });
          }
          },
          getSupplierName(item){
            let res = '';
            this.suppliers.forEach(el => {
              if(el.SupplierID ==  item){
                res = el.CompanyName
              }
            });
          return res;

          },
          deleteItem() {
            this.dialogDelete = true
          },
          deleteItemConfirm () {
            this.AOQLists.forEach(item => {
              let index = this.formData.AOQLists.indexOf(item);
              this.formData.AOQLists.splice(index, 1)
            });
            this.AOQLists = [];
            this.dialogDelete = false
          },
          computeAmount(){
            let amount = this.AOQFormdata.supplierAmount
            let qty = this.AOQFormdata.supplierQty
            this.AOQFormdata.supplierUnitCost = (amount / qty).toFixed(2)
          },
          computeQuotation(){
            let unitCost = this.AOQFormdata.supplierUnitCost
            let qty = this.AOQFormdata.supplierQty
            this.AOQFormdata.supplierAmount = (unitCost * qty).toFixed(2)
          },
          getOverAllTotal(){
            let total = 0;
            this.formData.AOQLists.forEach(item => {
              total = total + (parseFloat(item.Qty) * parseFloat(item.UnitCost))
            });
            return total.toFixed(2)
          },
          getTotalPerSupplier(supplier){
            let total = 0;
            this.formData.AOQLists.forEach(item => {
              if(item.suppliers){
                item.suppliers.forEach(item2 => {
                  if(supplier == item2.SupplierID){
                    total = total + (parseFloat(item2.Qty) * parseFloat(item2.UnitCost))
                  }
                });
              }
            });
            return total.toFixed(2)
          }
        }
      }
    </script>
        
    <style scoped>
    .v-dialog--fullscreen, .prModal {
      overflow-x: hidden !important;
    }
    </style>